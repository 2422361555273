<template>
  <div>
    <el-row class="re">
      <el-button @click="re" type="primary">刷新</el-button>
    </el-row>
    <el-tabs v-model="activeName">
      <el-tab-pane label="成员管理" name="first">
        <el-table
            ref="multipleTable"
            :data="tableData.records"
            tooltip-effect="dark"
            style="width: 100%">
          <el-table-column
              prop="username"
              label="姓名"
              width="100">
          </el-table-column>

          <el-table-column
              prop="userImg"
              label="头像"
              width="120">
            <template slot-scope="scope">
              <el-avatar :src=scope.row.userImg></el-avatar>
            </template>
          </el-table-column>
          <el-table-column
              prop="level"
              label="等级"
              width="120">
            <template slot-scope="scope">
              <el-tag effect="dark" type="danger" v-if="scope.row.level===0">
                {{ getLevel(scope.row.level) }}
              </el-tag>
              <el-tag effect="dark" type="primary" v-else>
                {{ getLevel(scope.row.level) }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column
              prop="userTelephone"
              label="电话号码"
              width="120">
          </el-table-column>
          <el-table-column
              prop="userEmail"
              label="邮箱"
              width="180">
          </el-table-column>
          <el-table-column
              sortable
              prop="gmtCreate"
              label="加入时间"
              width="200">
          </el-table-column>
        </el-table>
        <el-pagination
            v-if="tableData"
            background
            :current-page="tableData.current"
            :page-size="tableData.size"
            @current-change="getPass"
            layout="prev, pager, next"
            :total="tableData.total">
        </el-pagination>
      </el-tab-pane>


      <el-tab-pane  label="待通过" name="second">
        <el-table
            ref="noPassTable"
            :data="noPassData.records"
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="handleSelectionChange">
          <el-table-column
              type="selection"
              width="55">
          </el-table-column>
          <el-table-column
              prop="username"
              label="姓名"
              width="100">
          </el-table-column>
          <el-table-column
              prop="userImg"
              label="头像"
              width="120">
            <template slot-scope="scope">
              <el-avatar :src=scope.row.userImg></el-avatar>
            </template>
          </el-table-column>
          <el-table-column
              prop="userTelephone"
              label="电话号码"
              width="120">
          </el-table-column>
          <el-table-column
              prop="userEmail"
              label="邮箱"
              width="180">
          </el-table-column>
          <el-table-column
              sortable
              prop="gmtCreate"
              label="加入时间"
              width="200">
          </el-table-column>
        </el-table>
        <el-row class="btn">
          <el-button type="primary" @click="pass">通过</el-button>
          <el-button type="primary" @click="toggleSelection()">取消选择</el-button>
        </el-row>
        <el-pagination
            v-if="noPassData"
            background
            :current-page="noPassData.current"
            :page-size="noPassData.size"
            @current-change="getPass"
            layout="prev, pager, next"
            :total="noPassData.total">
        </el-pagination>
      </el-tab-pane>

    </el-tabs>


  </div>
</template>

<script>
import {passCrowInfo, selectCrowdInfoById} from "@/api/crowd";

export default {
  name: "member",
  mounted() {
    // console.log("member"+)
    this.id = this.$route.params.id;
    this.getPass(1);
    this.getNoPass(1);
  },
  data() {
    return {
      activeName: 'first',
      tableData: [],
      multipleSelection: [],
      id: "",
      noPassData: [],
      noPassSelection: [],
      manager:false,
    };
  },
  methods: {
    re() {
      this.getPass(1);
      this.getNoPass(1);
    },
    pass() {
      let ids = [];
      for (let i = 0; i < this.noPassSelection.length; i++) {
        // console.log(item);
        ids.push(this.noPassSelection[i].id);
      }
      // console.log(cl)
      passCrowInfo(ids).then(result => {
        this.$message(result.data.crowd);
        this.re();
      }).catch(error => {
        this.$message(error.message);
      })

    },
    getLevel(item) {
      if (item === 0) {
        return "群主"
      } else if (item === 1) {
        return "班助"
      } else {
        return "群众"
      }
    },
    getPass(number) {
      selectCrowdInfoById(this.id, number, 2).then(result => {
        this.tableData = result.data.crowd;
      }).catch(error => {
        this.$message(error.message)
      })
    },
    getNoPass(number) {
      selectCrowdInfoById(this.id, number, 1).then(result => {
        this.noPassData = result.data.crowd;
      }).catch(error => {
        this.$message(error.message)
      })
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.noPassTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.noPassTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.noPassSelection = val;
      console.log(this.noPassSelection)
    }
  }
}
</script>

<style scoped>
.btn {
  display: flex;
  margin: 10px auto;
}

.re {
  display: flex;
  margin: 10px auto;
}
</style>