import service from "@/utils/request";

/**
 * 添加班级信息
 * @returns {AxiosPromise}
 * @param clock
 */
export function addCrowd(crowd) {
    return service(
        {
            url: 'crowd/addCrowd',
            method: 'post',
            header: {
                "Content-Type": "application/json" //如果写成contentType会报错
            },
            data: crowd
        })
}

/**
 * 根据班级名称选人
 * @returns {AxiosPromise}
 * @param name 班级名称
 * @param current 当前页码
 */
export function selectCrowdByName(name, current) {
    return service(
        {
            url: 'crowd/selectCrowdByName',
            method: 'post',
            params: {
                name, current
            }
        })
}

/**
 * 根据班级id,来筛选
 * @param id
 * @returns {AxiosPromise}
 */
export function selectCrowdById(id) {
    return service(
        {
            url: 'crowd/selectCrowdById',
            method: 'post',
            params: {
                id
            }
        })
}


/**
 * 根据班级id，查看班级人员名单
 * @param id
 * @param current
 * @returns {AxiosPromise}
 */
export function selectCrowdInfoById(id, current, status) {
    return service(
        {
            url: 'crowd/selectCrowdInfoById',
            method: 'post',
            params: {
                id, current, status
            }
        })
}

/**
 * 盛情加入班级
 * @param crowdInfo
 * @returns {*}
 */
export function addCrowdInfo(crowdInfo) {
    return service(
        {
            url: 'crowd/addCrowdInfo',
            method: 'post',
            header: {
                "Content-Type": "application/json" //如果写成contentType会报错
            },
            data: crowdInfo
        })
}

/**
 * 根据用户id,来查找用户加入的班级信息
 * @param uid
 * @returns {AxiosPromise}
 */
export function selectCrowdByUserId(uid) {
    return service(
        {
            url: 'crowd/selectCrowdByUserId',
            method: 'post',
            params: {
                uid
            }
        })
}

/**
 * 审核通过班级信息
 * @returns {*}
 * @param ids
 */
export function passCrowInfo(ids) {
    console.log(JSON.stringify(ids));
    return service(
        {
            url: 'crowd/passCrowInfo',
            method: 'post',
            header: {
                "Content-Type": "application/json" //如果写成contentType会报错
            },
            data: ids
        })
}